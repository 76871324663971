<template>
  <a-drawer
    width="1000"
    :label-col="4"
    :wrapper-col="14"
    :visible="open"
    @close="onClose"
    :destroyOnClose="true"
  >
    <a-divider orientation="left">
      <b>详情</b>
    </a-divider>
    <!-- <div style="margin-bottom:20px;">
      <a-radio-group
        v-model="type"
        button-style="solid"
      >
        <a-radio-button :value="1">设备信息</a-radio-button>
        <a-radio-button
          :value="2"
          v-if="form.agentId"
        >代理商信息</a-radio-button>
        <a-radio-button
          :value="3"
          v-if="form.userId && form.userId !== '0'"
        >商家信息</a-radio-button>
      </a-radio-group>
    </div> -->
    <!-- <a-button
      type="primary"
      v-print="'#printContent'"
      @click="$refs.createForm.handleAdd()"
    >
      打印
    </a-button> -->
    <div id="printContent">
      <a-descriptions
        bordered
        :column="3"
        size="middle"
      >
        <a-descriptions-item label="设备名称">{{ form.devName }}</a-descriptions-item>
        <a-descriptions-item label="设备序列号">{{ form.devCode }}</a-descriptions-item>
        <a-descriptions-item label="设备类型名称">{{ form.devTypeName }}</a-descriptions-item>
        <a-descriptions-item label="设备版本">{{ form.devVersion }}</a-descriptions-item>
        <a-descriptions-item label="控制器时间">{{ form.directorTime }}</a-descriptions-item>
        <a-descriptions-item label="接入平台时间">{{ form.accessPlatformTime }}</a-descriptions-item>
        <a-descriptions-item label="故障时间">{{ form.faultTime }}</a-descriptions-item>
        <a-descriptions-item label="在线时间">{{ form.onlineTime }}</a-descriptions-item>
        <a-descriptions-item label="离线时间">{{ form.offTime }}</a-descriptions-item>
        <a-descriptions-item label="是否在线 "> <a-tag
            color="green"
            v-if="form.onLine == 1"
          >在线</a-tag>
          <a-tag
            color="red"
            v-else-if="form.onLine == 2"
          >异常</a-tag>
          <a-tag
            color="red"
            v-else-if="form.onLine == 0"
          >离线</a-tag>
        </a-descriptions-item>
        <a-descriptions-item label="输出模式 "> <a-tag
            color="green"
            v-if="form.outPutPattern == 0"
          >自动</a-tag>
          <a-tag
            color="red"
            v-else-if="form.outPutPattern == 1"
          >手动</a-tag>
          <span v-else>--</span>
        </a-descriptions-item>
        <a-descriptions-item label="设备模式 "> <a-tag
            color="green"
            v-if="form.patternType == 0"
          >自动</a-tag>
          <a-tag
            color="red"
            v-else-if="form.patternType == 1"
          >手动</a-tag>
          <span v-else>--</span>
        </a-descriptions-item>
        <a-descriptions-item label="光伏状态 "> <a-tag
            color="green"
            v-if="form.photoVoltaicStatus == 0"
          >白天</a-tag>
          <a-tag
            color="red"
            v-else-if="form.photoVoltaicStatus == 1"
          >手动</a-tag>
          <span v-else>--</span>
        </a-descriptions-item>
        <a-descriptions-item label="是否息屏 "> <a-tag
            color="green"
            v-if="form.sfBreathingPlate == 0"
          >否</a-tag>
          <a-tag
            color="red"
            v-else-if="form.sfBreathingPlate == 1"
          >是</a-tag>
          <span v-else>--</span>
        </a-descriptions-item>
        <a-descriptions-item label="是否息屏 "> <a-tag
            color="green"
            v-if="form.sfBreathingPlate == 0"
          >否</a-tag>
          <a-tag
            color="red"
            v-else-if="form.sfBreathingPlate == 1"
          >是</a-tag>
          <span v-else>--</span>
        </a-descriptions-item>
        <a-descriptions-item label="辅助激光状态 "> <a-tag
            color="green"
            v-if="form.assistLaser == 0"
          >开</a-tag>
          <a-tag
            color="red"
            v-else-if="form.assistLaser == 1"
          >关</a-tag>
          <span v-else>--</span>
        </a-descriptions-item>
        <a-descriptions-item label="辅助预警状态 "> <a-tag
            color="green"
            v-if="form.assistStatus == 0"
          >开</a-tag>
          <a-tag
            color="red"
            v-else-if="form.assistStatus == 1"
          >关</a-tag>
          <span v-else>--</span>
        </a-descriptions-item>
        <a-descriptions-item label="警示颜色">
          {{ form.cautionColour == 0 ? '红色' : form.cautionColour == 1 ? '绿色' : form.cautionColour == 2 ? '黄色' : '--' }}
        </a-descriptions-item>
        <a-descriptions-item label="警示灯闪烁模式">{{ form.cautionLightModel }}</a-descriptions-item>
        <a-descriptions-item label="负载模式">
          <a-tag
            color="green"
            v-if="form.loadStatus == 0"
          >开</a-tag>
          <a-tag
            color="red"
            v-else-if="form.loadStatus == 1"
          >关</a-tag>
          <span v-else>--</span>
        </a-descriptions-item>
        <a-descriptions-item label="碰撞预警状态">
          <a-tag
            color="green"
            v-if="form.crashStatus == 0"
          >开</a-tag>
          <a-tag
            color="red"
            v-else-if="form.crashStatus == 1"
          >关</a-tag>
          <span v-else>--</span>
        </a-descriptions-item>
        <a-descriptions-item label="本地音频时长">{{ form.audioDuration }}</a-descriptions-item>
        <a-descriptions-item label="预警状态">{{ form.earlyStatus == 0 ? '复位' : '' }}</a-descriptions-item>
        <a-descriptions-item label="故障显示信息">{{ form.faultMsg }}</a-descriptions-item>
        <a-descriptions-item label="报警音调"> {{ form.giveAlarmTone == 0 ? 'Wail' : form.giveAlarmTone == 1 ? 'Yelp' :
          form.giveAlarmTone == 2 ? 'Hi-lo' : form.giveAlarmTone == 3 ? 'Hyper-Yelp' : form.giveAlarmTone == 4 ?
            'Hyper-Lo'
            : form.giveAlarmTone == 5 ? 'Whoop' : '--' }}</a-descriptions-item>
        <a-descriptions-item label="本地有车语音开关">
          <a-tag
            color="green"
            v-if="form.localHaveAudioSatus == 0"
          >开</a-tag>
          <a-tag
            color="red"
            v-else-if="form.localHaveAudioSatus == 1"
          >关</a-tag>
          <span v-else>--</span>
        </a-descriptions-item>
        <a-descriptions-item label="本地未超速显示文字">{{ form.localNotSpeedText }}</a-descriptions-item>
        <a-descriptions-item label="本地未超速显示文字颜色">{{ form.localNotSpeedTextColourText }}</a-descriptions-item>
        <a-descriptions-item label="本地未超速显示文字模式">
          {{ form.localNotSpeedTextModel == 0 ? '静止' : form.localNotSpeedTextModel == 1 ? '左移' :
            form.localNotSpeedTextModel
              == 2 ? '右移' :
              form.localNotSpeedTextModel == 3 ? '上移' : form.localNotSpeedTextModel == 4 ? '下移' : form.localNotSpeedTextModel
                ==
                5 ? '闪烁' :
                '--' }}
        </a-descriptions-item>
        <a-descriptions-item label="本地未超速显示文字速度">{{ form.localNotSpeedTextSpeed }}</a-descriptions-item>
        <a-descriptions-item label="本地未超速显示文字停留时间">{{ form.localNotSpeedTextWaitTime }}</a-descriptions-item>
        <a-descriptions-item label="本地数据显示时间">{{ form.localShowDuration }}</a-descriptions-item>
        <a-descriptions-item label="本地已超速显示文字">{{ form.localThenSpeedText }}</a-descriptions-item>
        <a-descriptions-item label="本地已超速显示文字颜色">{{ form.localThenSpeedTextColourText }}</a-descriptions-item>
        <a-descriptions-item label="本地已超速显示文字模式">
          {{ form.localThenSpeedTextModel == 0 ? '静止' : form.localThenSpeedTextModel == 1 ? '左移' :
            form.localThenSpeedTextModel
              == 2 ? '右移' :
              form.localThenSpeedTextModel == 3 ? '上移' : form.localThenSpeedTextModel == 4 ? '下移' :
                form.localThenSpeedTextModel
                  ==
                  5 ? '闪烁' :
                  '--' }}
        </a-descriptions-item>
        <a-descriptions-item label="本地已超速显示文字速度">{{ form.localThenSpeedTextSpeed }}</a-descriptions-item>
        <a-descriptions-item label="本地已超速显示文字停留时间">{{ form.localThenSpeedTextWaitTime }}</a-descriptions-item>
        <a-descriptions-item label="待机显示文字">{{ form.standbyText }}</a-descriptions-item>
        <a-descriptions-item label="待机显示文字颜色">{{ form.standbyTextColourText }}</a-descriptions-item>
        <a-descriptions-item label="待机显示文字模式">
          {{ form.standbyTextModel == 0 ? '静止' : form.standbyTextModel == 1 ? '左移' : form.standbyTextModel
            == 2 ? '右移' :
            form.standbyTextModel == 3 ? '上移' : form.standbyTextModel == 4 ? '下移' : form.standbyTextModel ==
              5 ? '闪烁' :
              '--' }}
        </a-descriptions-item>
        <a-descriptions-item label="待机显示文字速度">{{ form.standbyTextSpeed }}</a-descriptions-item>
        <a-descriptions-item label="待机显示文字停留时间">{{ form.standbyTextWaitTime }}</a-descriptions-item>
        <a-descriptions-item label="待机显示时间">{{ form.standbyTime }}</a-descriptions-item>
        <a-descriptions-item label="待机音调"> {{ form.standbyTone == 0 ? 'Wail' : form.standbyTone == 1 ? 'Yelp' :
          form.standbyTone == 2 ? 'Hi-lo' : form.standbyTone == 3 ? 'Hyper-Yelp' : form.standbyTone == 4 ? 'Hyper-Lo'
            : form.standbyTone == 5 ? 'Whoop' : '--' }}</a-descriptions-item>
        <a-descriptions-item label="对向有车音频开关">
          <a-tag
            color="green"
            v-if="form.subtendHaveAudioSatus == 0"
          >开</a-tag>
          <a-tag
            color="red"
            v-else-if="form.subtendHaveAudioSatus == 1"
          >关</a-tag>
          <span v-else>--</span>
        </a-descriptions-item>
        <a-descriptions-item label="对向来车告警开关">
          <a-tag
            color="green"
            v-if="form.subtendHaveWarningStatus == 0"
          >开</a-tag>
          <a-tag
            color="red"
            v-else-if="form.subtendHaveWarningStatus == 1"
          >关</a-tag>
          <span v-else>--</span>
        </a-descriptions-item>
        <a-descriptions-item label="对向未超速显示文字">{{ form.subtendNotSpeedText }}</a-descriptions-item>
        <a-descriptions-item label="对向未超速显示文字颜色">{{ form.subtendNotSpeedText }}</a-descriptions-item>
        <a-descriptions-item label="对向未超速显示文字模式 ">
          {{ form.subtendNotSpeedTextModel == 0 ? '静止' : form.subtendNotSpeedTextModel == 1 ? '左移' :
            form.subtendNotSpeedTextModel
              == 2 ? '右移' :
              form.subtendNotSpeedTextModel == 3 ? '上移' : form.subtendNotSpeedTextModel == 4 ? '下移' :
                form.subtendNotSpeedTextModel ==
                  5 ? '闪烁' :
                  '--' }}
        </a-descriptions-item>
        <a-descriptions-item label="对向未超速显示文字速度">{{ form.subtendNotSpeedTextSpeed }}</a-descriptions-item>
        <a-descriptions-item label="对向未超速显示文字停留时间">{{ form.subtendNotSpeedTextWaitTime }}</a-descriptions-item>
        <a-descriptions-item label="对向数据显示时间">{{ form.subtendShowDuration }}</a-descriptions-item>
        <a-descriptions-item label="对向已超速显示文字">{{ form.subtendThenSpeedText }}</a-descriptions-item>
        <a-descriptions-item label="对向已超速显示文字颜色">{{ form.subtendThenSpeedText }}</a-descriptions-item>
        <a-descriptions-item label="对向已超速显示文字模式 ">
          {{ form.subtendThenSpeedTextModel == 0 ? '静止' : form.subtendThenSpeedTextModel == 1 ? '左移' :
            form.subtendThenSpeedTextModel
              == 2 ? '右移' :
              form.subtendThenSpeedTextModel == 3 ? '上移' : form.subtendThenSpeedTextModel == 4 ? '下移' :
                form.subtendThenSpeedTextModel ==
                  5 ? '闪烁' :
                  '--' }}
        </a-descriptions-item>
        <a-descriptions-item label="对向已超速显示文字速度">{{ form.subtendThenSpeedTextSpeed }}</a-descriptions-item>
        <a-descriptions-item label="对向已超速显示文字停留时间">{{ form.subtendThenSpeedTextWaitTime }}</a-descriptions-item>
        <a-descriptions-item label="对向数据有效时间">{{ form.subtendValidDuration }}</a-descriptions-item>
        <a-descriptions-item label="顶部显示文字">{{ form.topText }}</a-descriptions-item>
        <a-descriptions-item label="顶部显示文字颜色">{{ form.topTextColourText }}</a-descriptions-item>
        <a-descriptions-item label="顶部显示文字模式  ">
          {{ form.topTextModel == 0 ? '静止' : form.topTextModel == 1 ? '左移' :
            form.topTextModel
              == 2 ? '右移' :
              form.topTextModel == 3 ? '上移' : form.topTextModel == 4 ? '下移' :
                form.topTextModel ==
                  5 ? '闪烁' :
                  '--' }}
        </a-descriptions-item>
        <a-descriptions-item label="顶部显示文字速度">{{ form.topTextSpeed }}</a-descriptions-item>
        <a-descriptions-item label="顶部显示文字停留时间">{{ form.topTextWaitTime }}</a-descriptions-item>
        <a-descriptions-item label="对向数据有效时间">{{ form.subtendValidDuration }}</a-descriptions-item>
        <a-descriptions-item label="语音音量1"> {{ form.voiceVolume1 == 0 ? '无' : form.voiceVolume1 == 1 ? '低' :
          form.voiceVolume1 == 2 ? '中' : form.voiceVolume1 == 3 ? '高' : '--' }}</a-descriptions-item>
        <a-descriptions-item label="语音音量2"> {{ form.voiceVolume2 == 0 ? '无' : form.voiceVolume2 == 1 ? '低' :
          form.voiceVolume2 == 2 ? '中' : form.voiceVolume2 == 3 ? '高' : '--' }}</a-descriptions-item>
        <a-descriptions-item label="屏幕亮度1">{{ form.screenLuminance1 }}</a-descriptions-item>
        <a-descriptions-item label="屏幕亮度2">{{ form.screenLuminance2 }}</a-descriptions-item>
        <a-descriptions-item label="工作时段1">{{ form.workingHours1 }}</a-descriptions-item>
        <a-descriptions-item label="工作时段2">{{ form.workingHours2 }}</a-descriptions-item>
        <a-descriptions-item label="天气信息">
          <a-tag
            color="green"
            v-if="form.weatherStatus == 0"
          >开</a-tag>
          <a-tag
            color="red"
            v-else-if="form.weatherStatus == 1"
          >关</a-tag>
          <span v-else>--</span>
        </a-descriptions-item>
        <a-descriptions-item label="无线音频时长">{{ form.wirelessAudioDuration }}</a-descriptions-item>
        <a-descriptions-item label="本地数据显示时间">{{ form.localShowDuration }}</a-descriptions-item>
        <a-descriptions-item label="总发电量">{{ form.electricQuantityAll }}</a-descriptions-item>
        <!-- <a-descriptions-item label="IMSI">{{ form.imsi }}</a-descriptions-item> -->
        <a-descriptions-item label="当日发电量">{{ form.electricQuantityNowDay }}</a-descriptions-item>
        <!-- <a-descriptions-item label="未超速显示文字">{{ form.notSpeedText }}</a-descriptions-item>
        <a-descriptions-item label="未超速显示文字颜色">{{ form.subtendNotSpeedText }}</a-descriptions-item>
        <a-descriptions-item label="未超速显示文字模式">
          {{ form.notSpeedTextModel == 0 ? '静止' : form.notSpeedTextModel == 1 ? '左移' : form.notSpeedTextModel == 2 ? '右移'
            :
            form.notSpeedTextModel == 3 ? '上移' : form.notSpeedTextModel == 4 ? '下移' : form.notSpeedTextModel == 5 ? '闪烁' :
              '--' }}
        </a-descriptions-item>
        <a-descriptions-item label="未超速显示文字停留时间">{{ form.notSpeedTextWaitTime }}</a-descriptions-item> -->
        <a-descriptions-item label="来车方向">{{ form.nowCarDirection }}</a-descriptions-item>
        <a-descriptions-item label="来车数量">{{ form.nowCarNum }}</a-descriptions-item>
        <a-descriptions-item label="过车速度">{{ form.nowCarSpeed }}</a-descriptions-item>
        <a-descriptions-item label="过车时间">{{ form.nowCarTime }}</a-descriptions-item>

        <a-descriptions-item label="雷达测试距离">{{ form.radarDistance }}m</a-descriptions-item>
        <a-descriptions-item label="本地限速值">{{ form.rateLimiting }}</a-descriptions-item>
        <a-descriptions-item label="太阳能工作模式">{{ form.solarEnergyModel == 1 ? '节能' : '正常' }}</a-descriptions-item>
        <!-- <a-descriptions-item label="待机显示文字">{{ form.standbyText }}</a-descriptions-item>
        <a-descriptions-item label="待机显示文字模式">
          {{ form.standbyTextModel == 0 ? '静止' : form.standbyTextModel == 1 ? '左移' : form.standbyTextModel == 2 ? '右移' :
            form.standbyTextModel == 3 ? '上移' : form.standbyTextModel == 4 ? '下移' : form.standbyTextModel == 5 ? '闪烁' :
              '--' }}
        </a-descriptions-item>
        <a-descriptions-item label="待机显示文字停留时间">{{ form.standbyTextWaitTime }}</a-descriptions-item> -->
        <a-descriptions-item label="超速显示文字">{{ form.thenSpeedText }}</a-descriptions-item>
        <a-descriptions-item label="超速显示文字颜色">{{ form.localThenSpeedTextColourText }}</a-descriptions-item>
        <a-descriptions-item label="超速显示文字">{{ form.thenSpeedText }}</a-descriptions-item>
        <a-descriptions-item label="超速显示文字模式">
          {{ form.thenSpeedTextModel == 0 ? '静止' : form.thenSpeedTextModel == 1 ? '左移' : form.thenSpeedTextModel == 2 ?
            '右移'
            :
            form.thenSpeedTextModel == 3 ? '上移' : form.thenSpeedTextModel == 4 ? '下移' : form.thenSpeedTextModel == 5 ? '闪烁'
              :
              '--' }}
        </a-descriptions-item>
        <!-- <a-descriptions-item label="语音白天音量">{{ form.voiceVolumeDayTime }}</a-descriptions-item>
        <a-descriptions-item label="语音夜晚音量">{{ form.voiceVolumeNight }}</a-descriptions-item> -->
        <a-descriptions-item label="是否超速">{{ form.warningText ? '超速' : '未超速' }}</a-descriptions-item>
        <a-descriptions-item label="天气信息">{{ form.weatherText }}</a-descriptions-item>

        <a-descriptions-item label="供电">{{ form.powerSupply }}</a-descriptions-item>
        <a-descriptions-item label="雷达测试距离">{{ form.radarDistance }}M</a-descriptions-item>
        <a-descriptions-item label="MP3播放音量">{{ form.mp3Volume }}</a-descriptions-item>
        <a-descriptions-item label="备注">{{ form.remark }}</a-descriptions-item>
      </a-descriptions>
    </div>
  </a-drawer>
</template>

<script>

import { getDevice } from '@/api/project/device'
// import { getUser } from '@/api/user/agent'
// import { getUsers } from '@/api/user/enterprise'
export default {
  name: 'CreateForm',
  data () {
    return {
      submitLoading: false,
      form: {},
      type: 1,
      open: false,
      agentForm: {},
      userForm: {}
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 点击tab
    callback (key) {

    },
    info (id) {
      getDevice({ id }).then(response => {
        this.form = response.data
        this.open = true
        // this.$nextTick(() => {
        //   this.$refs.map.mapInit(response.data.log, response.data.lat)
        // })
      })
    }
  }
}
</script>
<style lang="less" scoped>
.ant-upload-preview {
  position: relative;
  width: 100px;
  height: 100px;

  .upload-icon {
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 1.4rem;
    padding: 0.5rem;
    background: rgba(222, 221, 221, 0.7);
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  .mask {
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    transition: opacity 0.4s;

    &:hover {
      opacity: 1;
    }

    i {
      font-size: 2rem;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -1rem;
      margin-top: -1rem;
      color: #d6d6d6;
    }
  }

  img,
  .mask {
    width: 100%;
    max-width: 180px;
    height: 100%;
    overflow: hidden;
    object-fit: contain;
  }
}
</style>
